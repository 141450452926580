import React from 'react'
import { Col } from 'react-bootstrap'

const blogStyle = {background: "linear-gradient(45deg, rgba(255,183,107,1) 0%,rgba(255,167,61,1) 50%,rgba(255,124,0,1) 51%,rgba(255,127,4,1) 100%)"}

function Blog(){
    return (
      <Col sm={12} md={12} lg={12}className="db br2 ba b--black-10 shadow-1 pa4" style={blogStyle}>
        <h1>
          Look out for my NEW blog Thoughts From A Thinker. Coming this December. Just wait a Little longer!!
        </h1>
      </Col>
        )
}

export default Blog 