import React, { Component } from 'react';
import './App.css';
import './bootstrap.min.css'
import './bootstrap-social.css'
import { Grid, Row } from 'react-bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faLinkedin, faReddit, faGithub, faQuora, faStackOverflow} from '@fortawesome/free-brands-svg-icons'
import Header from '../src/Components/Header/Header.js'
import Summary from '../src/Components/Summary/Summary.js'
import Tech from '../src/Components/Tech/Tech.js'
import Resume from '../src/Components/Resume/Resume.js'
import Projects from '../src/Components/Projects/Projects.js'
import SocialMedia from '../src/Components/SocialMedia/SocialMedia.js'
import Blog from '../src/Components/Blog/Blog.js'
import Footer from '../src/Components/Footer/Footer.js'
import HmtAd from './Components/Ads/HmtAd.js';

library.add(fab, faGithub, faLinkedin, faReddit, faQuora, faStackOverflow)

class App extends Component {
  render(){
    return(       
      <Grid  className="text-center">
        <Row>

          <Header />

          <Summary />
    
          <Tech />
        
          <Resume />
        
          <Projects />

          <Blog />

          <SocialMedia />

          <HmtAd />
        
          <Footer />

        </Row>

      </Grid>
    )
  }
}

export default App;