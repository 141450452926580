import React from 'react';
import { Image, Col } from 'react-bootstrap'
import HMTlogo from '../../IMGS/LogoNew2018.png'

const HmtAdStyle = { background: "linear-gradient(135deg, #1e5799 0%,#2989d8 50%,#207cca 51%,#7db9e8 100%)"}

function HmtAd(props){
  return(
        <Col sm={12} md={12} lg={12} className="pa4 shadow-1 br2"  style={HmtAdStyle}>
          <Image src={HMTlogo} alt="logo"/>
          <h1>
            For all your Development and Recruitment needs check us out at
            <br /> 
            <a style={{color: "orange"}} href="https://www.hmtechz.com">
              HMTechz.com
            </a>
          </h1>
        </Col>
  ) 
}


export default HmtAd