import React from 'react'
import { Col, Image } from 'react-bootstrap'
import ravenousImg from '../../IMGS/ravenous.png'
import jammming from '../../IMGS/jammming.png'
import githubcards from '../../IMGS/githubcards.png'
import playnine from '../../IMGS/playnine.png'
import emergContacts from '../../IMGS/emergcontacts.png'
import rockPaperScissors from '../../IMGS/rockpaperscissors.png'

let listOfProjects = [
    {
      name: "Ravenous",
      description: "An application built with React. Using the Yelp Api to pull reviews and information on restaurants.",
      techUsed: ["React", "Javascript(ES6)", "JSX"],
      thumbnail: ravenousImg
    },
    {
      name: "Jammming",
      description: "An Application built using React. Connecting with the Spotify API to create playlist and add music to it.",
      techUsed: ["React", "Javascript(ES6)", "JSX"],
      thumbnail: jammming
    },
    {
      name: "GitHub Card App",
      description: "A React Application bit using GitHub API to display user info cards based on GitHub user names entered in the search bar.",
      techUsed: ["React", "Javascript(ES6)", "JSX"],
      thumbnail: githubcards
    }, 
    {
      name: "Play-Nine",
      description: "A game built using React, JSX, and ES6.The goal is to use all available numbers or combinations  equal the # of stars.",
      techUsed: ["React", "Javascript(ES6)", "JSX"], 
      thumbnail: playnine
    },
    {
      name: "Emergency Contacter",
      description: "A small application used to track an individuals contacts for emergencies. This application is built using React, Redux, and ES6.",
      techUsed: ["Javascript(ES6)", "React", "Redux"],
      thumbnail: emergContacts
    },
    {
      name: "Rock-Paper-Scissors",
      description: "A rock, paper, scissors game built using vanilla Javascript and ES6/",
      techUsed: ["Javascript(ES6)"],
      thumbnail: rockPaperScissors
    }
  ]

const Projects = function(){

    return(
        <Col id="projects"  className="flex flex-wrap br2 shadow-1 pb4" sm={12} md={12} lg={12} style={{"background-color": "#007bff"}}>
            <h2 className="display-5 w-100 b" style={{"color": "white"}}>
              Here's Some Projects I've Been Working On Lately
              <hr style={{"background-color": "white"}}/>
            </h2>
            {
              listOfProjects.map( project => {
              return <div key={project.name} className="card dib f3-ns ba b--black-10 shadow-1 v-btm ma0 pa2 w-third-l w-50-m">
                        <Image className="card-img-top h-50 grow" src={project.thumbnail} alt="Web Development Application" />
                        <h2 className="card-img-title">
                          {project.name}
                        </h2>
                        <p className="card-body">
                          {project.description}
                        </p>
                        <p className="btn btn-primary btn-sm grow"  
                            style={{position: "absolute", bottom: 0, left: 50, right: 50}}>
                          {project.techUsed.join(" - ")}
                        </p>
                      </div>
              })
            }
        </Col>
    )
}
export default Projects