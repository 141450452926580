import React from 'react'
import { Col } from 'react-bootstrap'

function Resume(props){
	return(
				<Col id="resume" className="card shadow-1 ">
					<header className="row card-title">
						<h2 className="col-12 display-5 roboto b">Resume</h2>
						<h3 className="col-12 display-5">Full Stack Software Engineer</h3>
					</header>
					<section className="row card-subtitle mb-2 text-muted">
						<h4 className="col-12">Summary</h4>
						<p className="col">
						An eager and consciousness fast learner with the ability to compliment an organization with a solid foundation and understanding of the SDLC and the stages of web development. 
						Proficient in Javascript and Modern JS frameworks such as React and Node. Along with the ability to help an organization with not only development but with hands on experience with business operations, technical recruiting, and marketing. 
						A strong passion for writing code that is easily readable and using the latest standards of web development. 
						</p>
					</section>
					<section className="row card-body">
						<h4 className="col">Experience</h4>
						<section className="col-12">
							<h5><a href="https://www.hmtechz.com">Hakuna Matata Technologies LLC.</a></h5>
							<h6>Founder &amp; Full Stack Sofware Engineer</h6>
							<ul className="list-unstyled">
								<li>Developing  Full Stack scalable web applications using Javascript(React/Node/Vue), HTML5, CSS3</li>
								<li>Integrating 3rd party API services with client products</li>
								<li>Tested front-end code in multiple browsers to ensure cross-browser compatibility.</li>
								<li>Maintained brand standards under company guidelines.s</li>
								<li>Consulted and documented UI best practices and code standards.</li>
								<li>Participated in projects in all stages of the product life cycle.</li>
								<li>Leveraged responsive web frameworks to consistently complete product deliverables ahead of schedule.</li>
							</ul>
						</section>
						<section className="col-12">
							<h5><a href="https://www.inrhythm.com">InRhythm</a></h5>
							<h6>Associate Sofware Engineer</h6>
							<ul className="list-unstyled">
								<li>Building web applications and internal tools using  Javascript(ES6), React, Node, AWS, Express, and ClaudiaJS</li>
								<li>Working with and receiving training and mentorship from Senior, Lead, and Director level engineers</li>
								<li>Transitioned from Sr. Technical Recruiter to Software Engineer</li>
							</ul>
						</section>
						<section className="col-12">
							<h5>To see my non-technical experience</h5>
							<a href="https://www.linkedin.com/in/andy-philbert/">
								<button className="btn btn-secondary">Click Here</button>
							</a>
						</section>
					</section>
				</Col>
	)
}

export default Resume 