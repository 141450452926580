import React from 'react'

import { Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function SocialMedia(){
  return(
      <Col  sm={12} md={12} lg={12} className="db br2 ba b--black-10 shadow-1 pb4" style={{"background-color": "white"}}>
        <header>
          <h1>Let's connect, add or message me.</h1>
        </header>
        <section>
          <div className="btn btn-social-icon btn-lg">
            <a href="https://www.linkedin.com/in/andy-philbert/"><FontAwesomeIcon icon={["fab", "linkedin"]} /></a>
          </div>
          <div className="btn btn-social-icon btn-lg">
            <a href="https://github.com/andyphilbertjr"><FontAwesomeIcon icon={["fab", "github"]} /></a>
          </div>
          <div className="btn btn-social-icon btn-lg">
            <a href="https://www.reddit.com/decodingBrooklyn"><FontAwesomeIcon icon={["fab", "reddit"]} /></a>
          </div>
          <div className="btn btn-social-icon btn-lg">
            <a href="https://stackoverflow.com/andy-philbert-jr"><FontAwesomeIcon icon={["fab", "stack-overflow"]} /></a>
          </div>
          <div className="btn btn-social-icon btn-lg">
            <a href="https://www.quora.com/profile/Andy-Philbert-Jr"><FontAwesomeIcon icon={["fab", "quora"]} /></a>
          </div>
          <div className="btn btn-social-icon btn-lg">
            <a href="https://www.facebook.com/andy.philbert"><FontAwesomeIcon icon={["fab", "facebook"]} /></a>
          </div>
          <div className="btn btn-social-icon btn-lg">
            <a href="https://twitter.com/Andy_Phils_U"><FontAwesomeIcon icon={["fab", "twitter"]} /></a>
          </div>
          <div className="btn btn-social-icon btn-lg">
            <a href="https://foursquare.com/andy_phils_u"><FontAwesomeIcon icon={["fab", "foursquare"]} /></a>
          </div> 
          <div className="btn btn-social-icon btn-lg">
            <a href="plus.google.com/+AndyPhilbertJr"><FontAwesomeIcon icon={["fab", "google-plus-g"]} /></a>
          </div>
          <div className="btn btn-social-icon btn-lg">
            <a href="https://www.bitbucket.com"><FontAwesomeIcon icon={["fab", "bitbucket"]} /></a>
          </div>  
          <div className="btn btn-social-icon btn-lg">
            <a href="https://www.spotify.com"><FontAwesomeIcon icon={["fab", "spotify"]} /></a>
          </div>
          <div className="btn btn-social-icon btn-lg">
            <a href="https://www.tumblr.com"><FontAwesomeIcon icon={["fab", "tumblr"]} /></a>
          </div> 
          <div className="btn btn-social-icon btn-lg">
            <a href="https://www.xbox.com"><FontAwesomeIcon icon={["fab", "xbox"]} /></a>
          </div>
          <div className="btn btn-social-icon btn-lg">
            <a href="https://www.youtube.com"><FontAwesomeIcon icon={["fab", "youtube"]} /></a>
          </div>   
          <div className="btn btn-social-icon btn-lg">
            <a href="https://www.yelp.com"><FontAwesomeIcon icon={["fab", "yelp"]} /></a>
          </div>
          <div className="btn btn-social-icon btn-lg">
            <a href="https://www.medium.com"><FontAwesomeIcon icon={["fab", "medium"]} /></a>
          </div> 
        </section>
      </Col>
  )
}

export default SocialMedia