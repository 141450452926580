import React from 'react'
import { Col, Image, Jumbotron, Button} from 'react-bootstrap'
import '../Summary/Summary.css'
import me from '../../IMGS/thmbNail2.jpg'


function Summary(props){
  
  return(
        <Col sm={12} md={12} lg={12} className="pa0">
          <Jumbotron id="aboutMe">
              <header>
                <Image id="profilePhoto" src={me} alt="Andy Philbert Jr" />
                <h1 className="display-4 b">&lt;h1&gt;Hello, World!&lt;/h1&gt;</h1>
                <p className="lead b">I'm a professional learner. That loves turning imagination into real life.</p>
                <hr id="divide" className="my-4"/>
                  <Button className="btn btn-primary btn-md b" href="https://www.linkedin.com/in/andy-philbert/" role="button">Learn More</Button>
              </header>
          </Jumbotron>
        </Col>
      )
}
 


export default Summary