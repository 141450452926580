import React from 'react'
import { Col } from 'react-bootstrap'

const Footer = function(){
  return(
    <Col sm={12} md={12} lg={12}>
      <h6>&copy; Andy Philbert Jr.</h6>
    </Col>
  )
}

export default Footer