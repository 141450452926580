import React from 'react'
import { Button, ButtonGroup, Nav, NavItem} from 'react-bootstrap'
 
const NAVBTNITEMS = ['About Me', 'Tech', 'Resume', 'Projects', 'Connect']

function Navigation(){

  return (
          <Nav>
            <ButtonGroup className="btn btn-primary w-100">
              {
                NAVBTNITEMS.map(navBtn => {
                  let navBtnLinks = () => {
                                        let btnStgToOneWrd = navBtn.split(' ').join('')
                                        let firstChar = btnStgToOneWrd.charAt(0)
                                        let btnHref = btnStgToOneWrd.replace(firstChar, firstChar.toLowerCase())
                                        console.log(`#${btnHref}`)
                                        return `#${btnHref}`
                  }
                  return  <NavItem className="w-100 ma0 pa0" key={navBtn} href={navBtnLinks()}>
                              <Button bsStyle="primary" bsSize="small" value={navBtn} className="dim white hover-orange">
                                {navBtn}
                              </Button>
                          </NavItem>
                }
              )
            }
          </ButtonGroup>
        </Nav>
        )
  }

  export {Navigation}