import React from 'react';
import { Col} from 'react-bootstrap'
import '../../IMGS/thmbNail1.jpg'
import { Navigation } from '../Nav/Nav.js'

function Header(){
  return (
      <Col sm={12} md={12} lg={12} className="pa0 roboto b">
        <h1>
          Andy Philbert Jr.
            <span className="badge badge-primary">
            Full Stack Software Engineer
            </span>
          </h1>
        <Navigation />
      </Col>
  )
}

export default Header 